import React, { useState } from "react";
import TextilColors from "./TextilColors";
import { API_BASE } from "../../constants";

const TextilItem = (props) => {
  const [showSizesAndColor, setShowSizesAndColor] = useState(false);

  const textil = props.textil;

  const getVelikosti = (velikosti) => {
    let resString = "";
    textil.sizes.map((oneVelikost) => (resString += oneVelikost.nazev + ", "));
    return resString.slice(0, -2);
  };

  const mapExcludeTextil = () => {
    if (props.excludedTextil === undefined) {
      return []
    } else {
      const excludedTextil = props.excludedTextil?.map((excl) => { return excl })
      return excludedTextil
    }
  }

  const onTextilClickHandler = (event) => {
    setShowSizesAndColor(!showSizesAndColor);
  };

  return (
    <React.Fragment>
      <div className="products_save_form">
        <article className="sep active">
          <div className="shirt clearfix" id={`stepOneTextil${textil.id}`} onClick={onTextilClickHandler}>
            <div className="thumb">
              <img src={`${API_BASE}/textil/mainPicture/${textil.id}`} alt="" />
            </div>
            <div className="content">
              <h2>{textil.nazev}</h2>
              <p>
                Velikosti:&nbsp; {getVelikosti(textil.sizes)}
                <br />
                Materiál: {textil.material}
                <br />
                Gramáž: {textil.gramaz}
                <br />
              </p>
              <p>
                Cena za ks s jednobarevným základním potiskem: <strong>{textil.prodejni_cena},00 Kč</strong>
              </p>
              <p>
                <a href={`${API_BASE}/textil/rozmery/${textil.id}`} target="_blank" rel="noreferrer">
                  Rozměry
                </a>
              </p>
            </div>
          </div>

          {showSizesAndColor && <TextilColors key={textil.id} color={textil.colors} size={textil.sizes} textilId={textil.id} textil={textil} excludedTextil={mapExcludeTextil(props.excludedTextil)} />}
        </article></div>
    </React.Fragment>
  );
};

export default TextilItem;
