import React, { useEffect, useCallback, useState } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import PrintItem from "../UI/PrintItem";
import BarvaPotisku from "../UI/BarvaPotisku";
import StepTwoService from "../../services/step-two";
import NickList from "./NickList";
import ribbonsService from "../../services/ribbons";
import dolniPismoService from "../../services/dolni-pismo.service";
import genericService from "../../services/generic.service";
import paramsService from "../../services/params.service";

const StepRibbons = () => {
    const cart = useSelector((state) => state.cart);
    const data = cart.data;
    const ribbonData = cart.data.ribbonsData;
    const dolniPismoData = cart.data.dolniPismoData;
    const dispatch = useDispatch();
    const pismoData = useSelector((state) => state.cart.data.dolniPismoData);
    const ribbonChosen = useSelector((state) => state.cart.basket.ribbons.chosen);
    const ribbonCartData = useSelector((state) => state.cart.basket.ribbons);
    const [barvaPotisku, setBarvaPotisku] = useState([]);
    const [params, setParams] = useState({});
    const [ribbonsNoTextil, setRibbonsNoTextil] = useState(0);
    const [ribbonsTextil, setRibbonsTextil] = useState(0);

    const getRibbonsDataData = useCallback(async () => {
        if (ribbonData === undefined || ribbonData.length === 0) {
            const ribbonsData = await ribbonsService.getAllItems();
            dispatch(
                cartActions.setData({
                    type: "RIBBONS",
                    data: ribbonsData[0].colors,
                    id: ribbonsData[0].id,
                })
            );
        }
        if (dolniPismoData === undefined || dolniPismoData.length === 0) {
            const dolniPismoData = await dolniPismoService.getAllItems();
            dispatch(
                cartActions.setData({
                    type: "DOLNI_PISMO",
                    data: dolniPismoData,
                })
            );
        }
    }, [ribbonData, dispatch, ribbonsService, dolniPismoData, dolniPismoService]);

    const fetchBarvaPotisku = useCallback(async () => {
        if (cart.data.barvaPotiskuData !== undefined && cart.data.barvaPotiskuData.length > 0) {
            setBarvaPotisku(cart.data.barvaPotiskuData);
        } else {
            const barvaPotiskuData = await StepTwoService.getAllBarvaPotisku();
            setBarvaPotisku(barvaPotiskuData);
            dispatch(
                cartActions.setData({
                    type: "BARVA_POTISKU",
                    data: barvaPotiskuData,
                })
            );
        }
    }, []);

    const onRibbonsCountChangeHandler = (event) => {
        let count = parseInt(event.target.value, 0);
        if (isNaN(count)) {
            count = 10;
        }

        dispatch(
            cartActions.setRibbonsCount({
                quantity: count,
            })
        );

        dispatch(cartActions.hideWarning());
    };

    const updateInput = useCallback(async (event) => {
        event.preventDefault();

        const count = parseInt(event.target.value, 0);
        if (count < 10) {
            dispatch(
                cartActions.setRibbonsCount({
                    quantity: 10,
                })
            );
        } else {
            dispatch(cartActions.hideWarning());
            dispatch(
                cartActions.setRibbonsCount({
                    quantity: count,
                })
            );
        }
    });

    const onRibbonsTextChangeHandler = (event) => {
        const value = event.target.value;
        dispatch(
            cartActions.setRibbonsText({
                value,
            })
        );
    };

    const getParams = useCallback(async () => {
        const paramsData = await paramsService.getAllParams();

        dispatch(
            cartActions.setData({
                type: "PARAMS",
                data: paramsData,
            })
        );
        setRibbonsNoTextil(genericService.getParamNValue(data, "RIBBON_NO_TEXTIL"));
        setRibbonsTextil(genericService.getParamNValue(data, "RIBBON_TEXTIL"));
    }, []);

    const checkStep = () => {
      if (!genericService.validateRibbons(cart.basket)) {
          dispatch(
              cartActions.setWarning({
                  message: "Vyberte písmo a barvu potisku.",
              })
          );
      }
  };
  const checkVersion = () => {
    if (cart?.minimumVersion === undefined) {
        dispatch(cartActions.resetStore({}));
    }
}
  useEffect(() => {
        getRibbonsDataData();
        fetchBarvaPotisku();
        setActivePage();
        getParams();
        checkStep();
        checkVersion()
    }, [getRibbonsDataData, fetchBarvaPotisku, getParams]);

    const textSerpyStyle = { width: "565px", height: "25px", marginLeft: "25px" };

    const setActivePage = () => {
        dispatch(
            cartActions.setActiveStep({
                step: "RIBBON",
            })
        );
    };

    return (
        <React.Fragment>
            <PrintItem
                printItemIdLabel="ribbons"
                printItemId={1}
                sliderId={33}
                itemList={ribbonData}
                itemStripLabel="Šerpa"
                itemType="ribbons"
                dataType="ribbons"
                endPoint={"ribbons/getPicture"}
                itemsPerPage={4}
                oneImageWidth={142}
                noPrint="false"
            />

            <div id="serpy_count_div" className="sliderInfo">
                <label htmlFor="serpyCount">Počet kusů </label>
                <input
                    name="serpyCount"
                    className="serpy_count_input"
                    type="number"
                    id="serpyCount"
                    disabled={!ribbonChosen}
                    value={ribbonCartData.quantity}
                    min="10"
                    onChange={onRibbonsCountChangeHandler}
                    onBlur={updateInput}
                />
                {!ribbonChosen && <span id="list_select_required_info">Nejprve vyberte typ šerpy!</span>}
                <div style={{ marginTop: "10px" }}>
                    Pokud si současně objednáte i textil, je cena šerpy {ribbonsTextil}Kč , při objednání šerp je cena za kus {ribbonsNoTextil}Kč. Minimální počet je 10 kusů.
                </div>
            </div>

            <br />

            <div id="own_text" className="relative">
                <h2 className="strip n1">Text šerpy</h2>
                <input name="data[serpy][text]" style={textSerpyStyle} defaultValue="MATURANT" type="text" id="serpyText" disabled={!ribbonChosen} onChange={onRibbonsTextChangeHandler} />
            </div>

            <br />

            <PrintItem
                printItemIdLabel="serpa_dolni_pismo"
                printItemId={1}
                itemList={pismoData}
                itemStripLabel="Druh písma"
                itemType="serpaDolniPismo"
                dataType="dolniPismo"
                endPoint={"dolni-pismo"}
                itemsPerPage={4}
                noPrint="false"
                // svg={true}
            />
            <BarvaPotisku barvaPotisku={cart.data.barvaPotiskuData} ribbons={true} />
            <NickList data={cart.data} />
        </React.Fragment>
    );
};

export default StepRibbons;
