import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from "../../store/cart-slice";
import paramsService from '../../services/params.service';

const StepSix = () => {
    const dispatch = useDispatch();
    const paddingStyle = { "padding": "10px" }
    const [successMessage, setSuccessMessage] = useState();
    const cart = useSelector((state) => state.cart);

    const setActivePage = () => {
        dispatch(
            cartActions.setActiveStep({
                step: "SIX",
            })
        );

    };
    const checkVersion = () => {
        if (cart?.minimumVersion === undefined) {
            dispatch(cartActions.resetStore({}));
        }
    }
    useEffect(() => {
        setActivePage();
        async function successMessage() {
            const params = await paramsService.getAllParams();
            const successMessage = params?.find((item) => item.code === 'SUCCESS_SUBMIT_MESSAGE')?.cvalue;
            setSuccessMessage(successMessage)
        }
        successMessage()
        dispatch(cartActions.orderSubmitted({}));
        checkVersion()
    }, []);


    return (
        <React.Fragment>

            <div id="stepSix" style={paddingStyle}>
                <h1>Děkujeme</h1>
                <h2><br /> {successMessage}</h2>
            </div>
        </React.Fragment>
    )
}

export default StepSix