import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";

const DuplicateOrderDialog = (props) => {

    const [open, setOpen] = useState(false)
    let history = useNavigate();
    const dispatch = useDispatch();
    // const checkForExtras = useSelector((state) => state?.cart?.checkForExtras);


    const handleClose = () => {
        setOpen(false)
    }

    const handleOK = () => {
              dispatch(cartActions.resetStore({}));

        //  history("/");
        return;
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Objednávka již v systému existuje. <br />
                    Pokud jste neobdrželi email s potvrzením, kontaktujte nás prosím na info@kubyx.cz </DialogTitle>
                <DialogActions>
                    <Button onClick={handleOK} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DuplicateOrderDialog;
