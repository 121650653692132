import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PageTitle = (props) => {
    const activeStep = useSelector((state) => state.cart.activeStep);
    const [num, setNUm] = useState([]);
    const [text1, setText1] = useState([]);
    const [text2, setText2] = useState([]);



    useEffect(() => {
        switch (activeStep) {
            case "TWO":
                setNUm("02.")
                setText1("PŘEDNÍ POTISK")
                setText2("Vyberte si motivy potisku na přední stranu")
                break;
            case "THREE":
                setNUm("03.")
                setText1("ZADNÍ POTISK")
                setText2("Vyberte si motivy potisku na zadní stranu a napište seznam všech spolužáků")
                break;
            case "FOUR":
                setNUm("04.")
                setText1("POTISK RUKÁVŮ")
                setText2("Chcete potisknout i rukávy?")
                break;
            case "FIVE":
                setNUm("05.")
                setText1("SOUHRN")
                setText2("Doplňte údaje objednávky")
                break;
            default:
                setNUm("01.")
                setText1("Textil")
                setText2("Vyberte si textil, který chcete potisknout ")
        }
    }, [activeStep]);

    return (
        <React.Fragment>
            <span className="num">{num}</span>
            {text1}<small>{text2}</small>
        </React.Fragment>
    );
}



export default PageTitle;
