import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";

const AlertDialog = (props) => {

    const [open, setOpen] = useState(false)
    let history = useNavigate();
    const dispatch = useDispatch();
    const checkForExtras = useSelector((state) => state?.cart?.checkForExtras);


    const handleClose = () => {
        setOpen(false)
    }

    const handleRibbons = () => {
        // console.log("I agree!")
        handleClose()
        history("/stepRibbons");
        return;
    }

    const handleLists = () => {
        // console.log("I agree!")
        handleClose()
        history("/stepLists");
        return
    }

    const handleDisagree = () => {
        // console.log("I do not agree.")
        handleClose()
    }


    useEffect(() => {
        if (checkForExtras) {
            setOpen(false)
        } else {
            setOpen(props.open)
        }
        dispatch(
            cartActions.setCheckForExtras()
        )
    }, [])


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Přejete si objednat pamětní listiny nebo šerpy?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleRibbons} color="primary">
                        Šerpy
                    </Button>
                    <Button onClick={handleLists} color="primary"  >
                        Listiny
                    </Button>
                    <Button onClick={handleDisagree} color="primary"  >
                        Ne
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;
