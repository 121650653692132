import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import BasketSummary from "../BasketSummary/BasketSummary";
import PageTitle from "./PageTitle";
import { useSelector } from "react-redux";

const MenuItems = (props) => {
  const stepOneOnClickHandler = useCallback(async (event) => { });
  const activeStep = useSelector((state) => state.cart.activeStep);

  const getDoneFlag = (stepNo) => {
    if(activeStep === stepNo) {
      return " done"
    }
  }

  return (
    <React.Fragment>
      <BasketSummary />
      <div className={`box n1 ${getDoneFlag("ONE")}`}>
        <span className="num">01.</span>
        <h2>
          <a href="/4">Textil</a>
        </h2>
        <ul>
          <li>
            <a href="/4" onClick={stepOneOnClickHandler}>
              a) Trička{" "}
            </a>
          </li>
          <li>
            <a href="/5">b) Mikiny </a>
          </li>
          <li>
            <a href="/8">c) Dětské </a>
          </li>
          <li>
            <a href="/7">d) Ostatní </a>
          </li>
        </ul>
        <span className="check"></span>
      </div>

      <div className={`box n2 ${getDoneFlag("TWO")}`}>
        <span className="num">02.</span>

        <h2>
          <a href="/stepTwo">
            Přední <small>potisk</small>
          </a>
        </h2>
        <ul>
          <li>
            <a href="/stepTwo#horni_napis">a) Horní nápis</a>
          </li>
          <li>
            <a href="/stepTwo#obrazek">b) Obrázek</a>
          </li>
          <li>
            <a href="/stepTwo#dolni_napis">c) Dolní nápis</a>
          </li>
          <li>
            <a href="/stepTwo#dolni_napis">d) Barvy potisku</a>
          </li>
        </ul>
        <span className="check"></span>
      </div>

      <div className={`box n3 ${getDoneFlag("THREE")}`}>
        <span className="num">03.</span>
        <h2>
          <a href="/stepThree">
            Zadní <small>potisk</small>
          </a>
        </h2>
        <ul>
          <li>
            <a href="/stepThree#tvar_seznam_jmen">
              a) Tvar seznamu jmen
            </a>
          </li>
          <li>
            <a href="/stepThree#pismo">b) Druh písma</a>
          </li>
          <li>
            <a href="/stepThree#seznam">c) Seznam spolužáků</a>
          </li>
        </ul>
        <span className="check"></span>
      </div>

      <div className={`box n4 ${getDoneFlag("FOUR")}`}>
        <span className="num">04.</span>
        <h2>
          <a href="/stepFour">
            RukávŮ <small>potisk</small>
          </a>
        </h2>
        <span className="check"></span>
      </div>

      <div className={`box n5 ${getDoneFlag("FIVE")}`}>
        <span className="num">05.</span>
        <h2>
          <a href="/stepFive">Souhrn</a>
        </h2>
        <ul>
          <li>
            <a href="/stepFive#kontakt">a) Kontaktní údaje</a>
          </li>
        </ul>
        <span className="check"></span>
      </div>

      <h2 className="pageTitle" id="pageTitle">
        <PageTitle />
      </h2>
    </React.Fragment>
  );
};

const Menu = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <MenuItems props={props} />,
        document.getElementById("intro")
      )}
    </React.Fragment>
  );
};
export default Menu;
