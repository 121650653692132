import './App.css';
import TextilList from './components/StepOne/TextilList';
import StepTwo from './components/StepTwo/StepTwo'
import StepThree from './components/StepThree/StepThree'
import StepFour from './components/StepFour/StepFour';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import React from "react";
import Layout from './components/layout/Layout';
import StepRibbons from './components/StepRibbons/StepRibbons';
import StepLists from './components/StepLists/StepLists'
import StepFive from './components/StepFive/StepFive';
import StepSix from './components/StepSix/StepSix';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
function App() {
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        <Layout>
          <Router>
            <Routes>
              <Route element={<StepTwo />} path='/stepTwo' exact />
              <Route element={<StepThree />} path='/stepThree' exact />
              <Route element={<StepFour />} path='/stepFour' exact />
              <Route element={<StepRibbons />} path='/stepRibbons' exact />
              <Route element={<StepLists />} path='/stepLists' exact />
              <Route element={<StepFive />} path='/stepFive' exact />
              <Route element={<StepSix />} path='/stepSix' exact />
              {/* <Route element={<TextilList />} path='/stepOne/:catId' exact /> */}
              <Route element={<TextilList />} path='/' >
                <Route element={<TextilList />} path='/:catId' />
              </Route>
            </Routes>
          </Router>
        </Layout>
      </React.Fragment>
    </CacheBuster>
  );
}

export default App;
