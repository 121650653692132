import React, { useEffect, useState, useCallback } from 'react';
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';



const BarvaPotiskuItem = (data) => {
    const predniPotisk = useSelector((state) => state.cart.basket.predniPotisk);
    const cart = useSelector((state) => state.cart);

    const color = { backgroundColor: 'rgb(' + data.rgb + ')' }
    const dispatch = useDispatch();
    const kod = data.kod;
    const id = data.id;
    const label = 'barvaPotisku|' + kod + '|' + id;

    const onBarvaPotiskuClickHandler = (event) => {
        const id = event.target.id;
        const idSplit = id.split('|')
        if (`${idSplit[1]}|${idSplit[2]}|${idSplit[3]}` !== predniPotisk.barvaPotisku) {
            if (data.ribbons) {
                dispatch(
                    cartActions.setRibbonsPrintColor({
                        id: id,
                        price: data?.price
                    })
                );
            } else {
                dispatch(
                    cartActions.addBarvaPotiskToTheCart({
                        id: id
                    })
                );
            }

        }

        event.stopPropagation();
    }

    const isBarvaPotiskuActive = (kod) => {
        let status = '';
        let barvaPotisku = predniPotisk.barvaPotisku;
        if (data.ribbons) {
            barvaPotisku = 'barvaPotisku|' + cart.basket.ribbons.printColorCode + '|' + cart.basket.ribbons.printColorId;
        }
        if (kod === barvaPotisku) {
            status = 'active';
        }
        return status;
    }

    return (
        <React.Fragment>
            <tr>
                <td>
                    <a href="##" rel={data.kod} onClick={onBarvaPotiskuClickHandler} className={isBarvaPotiskuActive(label)} id={'href|' + label + '|' + data.popis}>
                        <span id={'span|' + label + '|' + data.popis} onClick={onBarvaPotiskuClickHandler} className="color" style={color} ></span>
                        {data.popis}
                    </a>
                </td>
            </tr>
        </React.Fragment>

    )
}

export default BarvaPotiskuItem
